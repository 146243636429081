<div class="alert-overlay" *ngIf="visible">
    <div class="alert-box">
      <span class="alert-close" (click)="closeAlert()">×</span>
      <h2 class="alert-title">{{ title }}</h2>
      <a></a>
      <p *ngIf="isTrackInfo" class="alert-message"> Enter Tracking Id:</p>
      <input *ngIf="isTrackInfo" id="tid" [(ngModel)]="tid" name="tid" required>
      <br>
      <br>
      <button *ngIf="isTrackInfo && !trackClicked" class="alert-ok" (click)="track()">Track</button>
      <img height="40px" width="40px" *ngIf="isLoading" src="/assets/images/loading.gif" alt="Loading..." />
      <!-- <p *ngIf="isTrackInfo && trackClicked && !isLoading" class="alert-message">Transaction status for your
        package is: {{ trackingStatus }}</p> -->

        <div  *ngIf="isTrackInfo && trackClicked && !isLoading" class="timeline-container">
          <h2 class="tracking-title">Delivery Tracking</h2>
          
          <div class="timeline">
            <!-- Start Date -->
            <!-- <div class="timeline-item">
              <div class="timeline-icon start"></div>
              <div class="timeline-content">
                <h4>Start Date</h4>
                <p>{{ startDate }}</p>
              </div>
            </div> -->
        
            <!-- Current Status -->
            <div class="timeline-item">
              <div class="timeline-icon current"></div>
              <div class="timeline-content">
                <h4>Status</h4>
                <p>{{ trackingStatus }}</p>
              </div>
            </div>
        
            <!-- Estimated Delivery -->
            <!-- <div class="timeline-item" *ngIf="!isDelivered">
              <div class="timeline-icon estimated"></div>
              <div class="timeline-content">
                <h4>Estimated Delivery</h4>
                <p>{{ estimatedDeliveryDate  }}</p>
              </div>
            </div>
         -->
            <!-- Delivered -->
            <div class="timeline-item" *ngIf="isDelivered">
              <div class="timeline-icon delivered"></div>
              <div class="timeline-content">
                <h4>Delivered</h4>
                <p>{{ deliveredDate }}</p>
              </div>
            </div>
          </div>
        </div>
        




      <p *ngIf="!isTrackInfo" class="alert-message" (click)="openWhatsapp()">Call or chat at <a [href]="linkUrl" target="_blank">9740585405</a> instant for booking of your package</p>
      <button *ngIf="!isTrackInfo || trackClicked" class="alert-ok" (click)="okay()">Okay</button>
    </div>
  </div>
  