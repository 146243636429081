import {layoutRouting} from './layout.routing';
import {FooterComponent, HeaderComponent, LayoutComponent, NoApiTokenComponent, ScrollTopComponent} from './components';
import {CommonModule} from '@angular/common';
import { DeliveryTrackingService } from 'src/app/layout/components/services/delivery-tracking.service'
import { TrackingService } from './components/alert/tracking.service';
import { FormsModule } from '@angular/forms';


export const COMPONENTS = [
  LayoutComponent,
  HeaderComponent,
  FooterComponent,
  ScrollTopComponent,
  NoApiTokenComponent,
];

export const IMPORTS = [
  layoutRouting,
  FormsModule,
  CommonModule
];

export const SERVICES = [DeliveryTrackingService,TrackingService];

export const EXPORTS = [];
