import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Consignee {
  City: string;
  Name: string;
  Address1: string[];
  Address2: string[];
  Address3: string;
  PinCode: number;
  State: string;
  Telephone2: string;
  Country: string;
  Telephone1: string;
}

export interface ScanDetail {
  ScanDateTime: string;
  ScanType: string;
  Scan: string;
  StatusDateTime: string;
  ScannedLocation: string;
  StatusCode: string;
  Instructions: string;
}

export interface Scan {
  ScanDetail: ScanDetail;
}

export interface Status {
  Status: string;
  StatusLocation: string;
  StatusDateTime: string;
  RecievedBy: string;
  StatusCode: string;
  StatusType: string;
  Instructions: string;
}

export interface Shipment {
  PickUpDate: string;
  Destination: string;
  DestRecieveDate: string | null;
  Scans: Scan[];
  Status: Status;
  ReturnPromisedDeliveryDate: string | null;
  Ewaybill: any[];
  InvoiceAmount: number;
  ChargedWeight: number | null;
  PickedupDate: string;
  DeliveryDate: string | null;
  SenderName: string;
  AWB: string;
  DispatchCount: number;
  OrderType: string;
  ReturnedDate: string | null;
  ExpectedDeliveryDate: string;
  RTOStartedDate: string | null;
  Extras: string;
  FirstAttemptDate: string | null;
  ReverseInTransit: boolean;
  Quantity: string;
  Origin: string;
  Consignee: Consignee;
  ReferenceNo: string;
  OutDestinationDate: string;
  CODAmount: number;
  PromisedDeliveryDate: string;
  PickupLocation: string;
  OriginRecieveDate: string;
}

export interface ShipmentData {
  Shipment: Shipment;
}

export interface RootObject {
  ShipmentData: ShipmentData[];
}

@Injectable({
  providedIn: 'root'
})
export class DeliveryTrackingService {

  //private apiUrl = 'https://track.delhivery.com/api/v1/packages/json/';
  private apiUrl = 'https://api.xpressdelivery.in/deltrack';

  private headers = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
    'Content-Type': 'application/json',
    'Authorization': '24a3117ce65e2195c77c73a81c0e64a2ec282054'
  });

  constructor(private http: HttpClient) {}

  getTrackingData(waybill: string): Observable<ShipmentData[]> {
    const url = `${this.apiUrl}?waybill=${waybill}`;
    return this.http.get<any>(url, { headers: this.headers })
    .pipe(map(response => response.ShipmentData));
    
    return this.http.get<RootObject>(this.apiUrl, { headers: this.headers })
      .pipe(map(response => response.ShipmentData));
  }
}
